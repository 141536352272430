import { TFunction } from 'i18next'
import { z } from 'zod'
import { regexEmailValidation } from '~/core/utilities/common'
import { removeHTMLTags } from '../../careers/[id]/utilities'
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE,
  convertFileSizeToBytes
} from '~/lib/schema'

export const schemaUpdateEmailProfile = (t: TFunction) => {
  return z.object({
    email: z
      .string({
        required_error: `${t('form:requiredField')}`
      })
      .refine((value) => value.trim() !== '', {
        message: `${t('form:requiredField')}`
      })
      .refine((value) => regexEmailValidation.test(value), {
        message: `${t('form:invalid_email')}`
      })
  })
}

export const schemaRejectApplicant = (t: TFunction) => {
  return z
    .object({
      rejectedReason: z
        .object({
          value: z.string(),
          supportingObj: z.object({ name: z.string() })
        })
        .nullish()
        .refine((obj) => !!obj?.value, {
          message: `${t('form:requiredField')}`
        }),
      sendRejectEmail: z.boolean().optional(),
      cc: z.array(z.object({ value: z.string() })).optional(),
      bcc: z.array(z.object({ value: z.string() })).optional(),
      subject: z.string().nullable().optional(),
      htmlBody: z.string().nullable().optional(),
      emailTemplate: z
        .object({
          value: z.string()
        })
        .optional()
    })
    .refine(
      (data) => {
        if (data.sendRejectEmail === false) {
          return true
        } else {
          return (data?.subject?.trim() || '').length > 0
        }
      },
      {
        message: `${t('form:subject_required')}`,
        path: ['subject']
      }
    )
    .refine(
      (data) => {
        if (data.sendRejectEmail === false) {
          return true
        } else {
          return removeHTMLTags(data?.htmlBody?.trim() || '').length > 0
        }
      },
      {
        message: `${t('form:email_content_required')}`,
        path: ['htmlBody']
      }
    )
}

const schemaCreateProfile = (t: TFunction) => {
  return z.object({
    fullName: z
      .string()
      .trim()
      .min(1, {
        message: `${t('form:requiredField')}`
      })
      .max(100, {
        message: `${t('form:field_max_number_required', { number: 100 })}`
      }),

    email: z
      .string()
      .refine((value) => regexEmailValidation.test(value), {
        message: `${t('form:invalid_email')}`
      })
      .optional()
      .or(z.literal('')),
    phoneNumber: z
      .string()
      .max(15, {
        message: `${t('form:invalid_phone_number')}`
      })
      .optional(),
    countryCode: z.any().optional(),
    resumeFile: z
      .any()
      .refine(
        (files) =>
          files?.[0]?.size <= convertFileSizeToBytes({ size: MAX_FILE_SIZE }),
        `${t('form:invalid_upload_file', {
          maximumSizeFile: MAX_FILE_SIZE
        })}`
      )
      .refine(
        (files) => ACCEPTED_FILE_TYPES.includes(files?.[0]?.type),
        `${t('form:invalid_upload_file', {
          maximumSizeFile: MAX_FILE_SIZE
        })}`
      )
      .optional(),
    jobId: z.array(z.object({ value: z.string() })).optional(),
    profileTalentPoolIds: z.array(z.object({ value: z.string() })).optional()
  })
}

export default schemaCreateProfile
