import { gql } from 'urql'

export type QueryTenantLocationVariable = {
  limit?: number
  page?: number
  search?: string
}
const QueryTenantLocation = gql<
  {
    tenantLocationsList: {
      collection: {
        id: number
        name: string
        address: string
        state: string
        country: string
        countryStateId: number
      }[]
      metadata: { totalCount: number }
    }
  },
  QueryTenantLocationVariable
>`
  query ($limit: Int!, $page: Int!, $search: String) {
    tenantLocationsList(limit: $limit, page: $page, search: $search) {
      collection {
        id
        name
        address
        headquarter
        country
        state
        city
        zipCode
        openJobsCount
        countryCode
        countryStateId
        countryState {
          country {
            id
          }
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTenantLocation
