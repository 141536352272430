import { FC, ReactNode } from 'react'
import { FormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '~/core/@types/global'
import { FormControlItem } from '~/core/ui/FormControlItem'
import If from '~/core/ui/If'
import { InputChips } from '~/core/ui/InputChips'
import { MultipleSelect } from '~/core/ui/MultipleSelect'
import { IEmailForm } from './EmailContentEditor'

const DropDownSelectEmailControl: FC<{
  onChange: (newValue: ISelectOption | ISelectOption[]) => void
  value?: ISelectOption | ISelectOption[]
  label: string
  autoFocus?: boolean
  rightAction?: ReactNode
  className?: string
  disabled?: boolean
  formState: FormState<IEmailForm>
  fieldName: 'to' | 'cc' | 'bcc'
  placeholder?: string
}> = ({
  formState,
  label,
  autoFocus = false,
  rightAction,
  value,
  className,
  onChange,
  disabled = false,
  fieldName,
  placeholder
}) => {
  const { t } = useTranslation()
  return (
    <div className={className}>
      <div className="w-[60px] flex-none text-sm text-gray-500">{label}</div>
      <div className="-mt-[2px] flex-1 overflow-hidden">
        <FormControlItem
        // destructive={formState.errors && !!formState.errors[fieldName]}
        // destructiveText={
        //   formState.errors && (formState.errors[fieldName]?.message as string)
        // }
        >
          {disabled ? (
            <div className="align-center flex flex-row flex-wrap px-[7px]">
              {((value as ISelectOption[]) || []).map((e) => (
                <div key={e.value} className="mr-2 mt-2 line-clamp-1">
                  <InputChips
                    isDragged={false}
                    label={e?.supportingObj?.name}
                    type="default"
                    size="sm"
                  />
                </div>
              ))}
            </div>
          ) : (
            <MultipleSelect
              autoFocus={autoFocus}
              variant="ghost"
              isDisabled={disabled}
              classNameOverride={{
                bordered: 'none',
                control: '!border-transparent !shadow-none !min-h-[20px]',
                valueContainer: 'py-0',
                loadingMessage: `${t('label:loading')}`,
                noOptionsMessage: `${t('label:noOptions')}`
              }}
              configSelectOption={{
                supportingText: ['name']
              }}
              showClearIndicator={false}
              showDropdownIndicator={false}
              placeholder={placeholder || ''}
              creatable
              isClearable
              onChange={(value) => onChange(value as ISelectOption[])}
              options={[]}
              size="sm"
              unstyled
              value={value}
              destructive={formState.errors && !!formState.errors[fieldName]}
            />
          )}
        </FormControlItem>
      </div>
      <If condition={rightAction}>{rightAction}</If>
    </div>
  )
}

export default DropDownSelectEmailControl
