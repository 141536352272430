import { FC, useEffect, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '~/core/@types/global'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { IPromiseSearchOption, SelectOption } from '~/core/ui/Select'
import { TextButton } from '~/core/ui/TextButton'
import useRejectCandidate from '~/lib/features/candidates/hooks/use-reject-candidate'
import { ITemplateType } from '~/lib/features/candidates/types'
import {
  IEmailResponseType,
  IEmailTemplate
} from '~/lib/features/settings/email-templates/types'

const SelectEmailTemplateControl: FC<{
  onChange: (newValue: ISelectOption) => void
  templateType?: ITemplateType
  onSetDefaultTemplate: (template: ISelectOption | IEmailTemplate) => void
  promiseEmailTemplatesList?: (
    params?: IPromiseSearchOption
  ) => Promise<IEmailResponseType>
}> = ({
  templateType,
  onChange,
  onSetDefaultTemplate,
  promiseEmailTemplatesList
}) => {
  const { t } = useTranslation()
  const [optionTemplate, setOptionTemplates] = useState<ISelectOption[]>([])
  const [toggleOpenExtraDropdown, setToggleOpenExtraDropdown] = useState(false)
  const { promiseEmailTemplates } = useRejectCandidate()

  useEffect(() => {
    if (promiseEmailTemplatesList) {
      promiseEmailTemplatesList().then((rs) => {
        if (rs.collection.length > 0 && rs.collection[0]) {
          onSetDefaultTemplate(rs.collection[0])
        }
        setOptionTemplates(rs.collection)
      })
    } else
      promiseEmailTemplates({
        page: 1,
        limit: 100,
        search: '',
        emailKind: templateType
      }).then((rs) => {
        if (rs.collection.length > 0 && rs.collection[0]) {
          onSetDefaultTemplate(rs.collection[0])
        }
        setOptionTemplates(rs.collection)
      })
  }, [])

  const refExtraDropdown = useOnclickOutside(
    () => {
      setToggleOpenExtraDropdown(false)
    },
    {
      ignoreClass: 'my-ignore-dropdown-email-template'
    }
  )

  return (
    <div className="flex items-center justify-end space-x-1">
      <div className="relative">
        <TextButton
          onClick={() => setToggleOpenExtraDropdown(true)}
          type="secondary"
          label={`${t('button:selectTemplate')}`}
          underline={false}
        />
        {toggleOpenExtraDropdown ? (
          <div
            ref={refExtraDropdown}
            className="absolute right-0 top-full z-50 w-[188px] pt-2.5">
            <div className="rounded bg-white shadow-ats outline-none dark:bg-gray-900 dark:shadow-dark-ats">
              <ScrollArea className="flex max-h-[338px] flex-col items-start p-1">
                {(optionTemplate || []).length !== 0 ? (
                  (optionTemplate || []).map((item, index) => (
                    <div
                      className="w-full cursor-pointer"
                      key={index}
                      onClick={() => {
                        onChange(item)
                        setToggleOpenExtraDropdown(false)
                      }}>
                      <SelectOption
                        size="sm"
                        data={item}
                        isOption={true}
                        isHeading={false}
                      />
                    </div>
                  ))
                ) : (
                  <div className="flex w-full items-center justify-center px-[10px] py-1.5">
                    <span className="text-sm font-normal text-gray-500">
                      {t('label:noOptions')}
                    </span>
                  </div>
                )}
              </ScrollArea>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SelectEmailTemplateControl
